<template>
    <div class="body">
        <nav-bar class="navbar">
            <div slot="left" class="certificateback" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">
                我的证书
            </div>
            <div slot="right" class="certificateresult"></div>
        </nav-bar>
        <div style="width: 100%;">
        <div class="certificate display-flex-between" >
            <router-link :to="{path:'/certificateDetail',query:{id:item.id}}" v-for="(item,index) in certificate" :key="index" class="certificate_item">
              <img v-if="item.is_valid!=1" src="../../assets/img/over.png" alt="" class="overtimes">
              <div class="itempic">
                <van-image
                  width="100%"
                  height="100%"
                  fit="cover"
                  :src="item.image_text"
                />
              </div>
                <div class="name">{{item.cert.name}}</div>
                <div class="time">有限期：{{item.end_time}}</div>
            </router-link>
            <router-link to="/uploadcertificate" style="color: #000;" class="certificate_uploadimg" >
                <img src="../../assets/img/unload.png" alt=""  class="upimg">
                <div class="uploadtext" >上传证书</div>
            </router-link>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      certificate: [],
      page: 1,
      pagenum: 1000
    }
  },
  mounted () {
    this.getlist()
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async getlist () {
      const params = {
        page: this.page,
        page_num: this.pagenum
      }
      const res = await this.api.certificate(params)
      if (res.code === 1) {
        const result = res.data.list.data
        result.forEach((item) => {
          item.end_time = item.end_time.split(' ')[0]
        })
        this.certificate = result
      } else {
        this.$toast(res.msg)
      }
    }
  }
}
</script>
<style lang="scss">
    .body{
        height: 100vh;
        background-color: #f7f8fa;
        overflow: auto !important;
    }
    .navbar{
        background: #fff;
    }
    .certificateback,.certificateresult{
        width: 1rem;
        font-size: 0.48rem;
    }
    .certificateback img{
        width: 0.48rem;
        height: 0.48rem;
    }
    .certificate{
        flex-wrap: wrap;
        padding: 0 0.32rem;
        box-sizing: border-box;
        margin-top: 1rem;
    }
    .certificate_item{
      position: relative;
        width: 3.2rem;
        height: 4.52rem;
        border-radius: 0.16rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        padding: 0.32rem;
        box-sizing: border-box;
        margin-bottom: 0.32rem;
    }
    .certificate_item .itempic{
        width: 100%;
        height: 2.56rem;
    }
    .name{
        font-size: 0.28rem;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 0.52rem;
        margin-top: 0.1rem;
        color: black;
    }
    .time{
        font-size: 0.24rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 0.4rem;
        color: rgba(150,151,153,1);
        margin-top: 0.1rem;
    }
    .uploadtext{
        font-size: 0.28rem;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 0.52rem;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translate(-50%,0);
    }
    .certificate_uploadimg{
      width: 50%;
        position: relative;
    }
    .certificate_uploadimg .upimg{
        width: 100%;
        height: 4.52rem;
    }
    .overtimes{
      width:1rem;
      height: 1rem;
      position: absolute;
      right: 0.24rem;
      top: 0.24rem;
      z-index: 8;
    }
</style>
