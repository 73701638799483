<template >
    <div class="lineclass">
        <nav-bar class="lineclassnavbar">
            <div slot="left" class="lineclassback" @click="goBack">
                <img src="../../assets/img/back3.png" alt=""/>
            </div>
            <div slot="center" class="lineclasscenter">
                {{detail.name}}
            </div>
            <div slot="right"></div>
        </nav-bar>
        <div class="video">
          <video-player ref="videoPlayer"
          class="vjs-custom-skin"
          :options="playerOptions"
          @play="onPlayerPlay($event)"
          @ready="onPlayerReady($event)">
          </video-player>
          <!-- <div id="id_test_video" style="width:100%; height:auto;"></div> -->
        </div>
    </div>
</template>
<script>
import '@/assets/style/lineClassDetail.css'
export default {
  data () {
    return {
      id: '',
      msg: '',
      detail: '',
      playerOptions: {
        autoplay: true,
        controls: true,
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          timeDivider: false,
          durationDisplay: false
        }
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
      }
    }
  },
  mounted () {
    this.id = this.$route.query.id
    // this.getDetail()
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    onPlayerPlay (player) {
      console.log('player play!', player)
    },
    onPlayerReady (player) {
      console.log('player ready!', player)
      this.player.play()
    },
    playVideo: function (source) {
      const video = {
        withCredentials: false,
        type: 'application/x-mpegURL',
        src: source
      }
      this.player.reset()
      this.player.src(video)
      this.player.play()
    },

    goBack () {
      this.$router.go(-1)
    },
    async getDetail () {
      const params = {
        id: this.id
      }
      const reslist = await this.api.lineclassdetail(params)
      if (reslist.code == 1) {
        this.detail = reslist.data.detail
        this.playVideo(this.detail.playback_url)
      }
    }
  }
}
</script>
<style scoped lang="scss">
 .vcp-player .marker{
      position: absolute;
      font-size: 20px;
      color: rgba(255, 255, 255, .5);
      top: 0;
      right: 5px;
  }
  .left{
    width: 0.6rem !important;
  }
  .lineclassback{
    width: 0.6rem !important;
  }
  .lineclasscenter{
    text-align: left;
    width: 7.6rem;
  }
  .player {
    position: absolute !important;
    width: 100%;
    height: 100%;
  }
  .vjs-custom-skin {
    width: 100% !important;
    height: 100% !important;
  }

  .vjs-custom-skin ::v-eddp .video-js {
    width: 100%;
    height: 100%;
  }
  .vjs-custom-skin ::v-eddp .video-js .vjs-big-play-button{
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%) !important;
  }
</style>
