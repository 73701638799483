import { render, staticRenderFns } from "./noticeDetail.vue?vue&type=template&id=e6a34804&"
import script from "./noticeDetail.vue?vue&type=script&lang=js&"
export * from "./noticeDetail.vue?vue&type=script&lang=js&"
import style0 from "./noticeDetail.vue?vue&type=style&index=0&id=e6a34804&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports