<template>
    <div class="container">
        <div class="content">
          <transition>
            <!-- <keep-alive> -->
              <router-view></router-view>
            <!-- </keep-alive> -->
          </transition>

        </div>
        <div  class="list display-flex-around">
            <router-link
            :to="item.link"
            class="link display-flex"
            v-for="(item,index) in tabs"
            :key="index"
            active-class="active"
            @click.native="chooseNav(index)"

            >
                <img :src="current==index?item.selectimage:item.image" alt="" />
                <span >{{item.test}}</span>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      current: 0,
      tabs: [
        {
          image: require('../../assets/img/homeer.png'),
          selectimage: require('../../assets/img/home.png'),
          test: '首页',
          link: '/home'
        },
        {
          image: require('../../assets/img/test0.png'),
          selectimage: require('../../assets/img/test.png'),
          test: '全能题库',
          link: '/test'
        },
        {
          image: require('../../assets/img/my0.png'),
          selectimage: require('../../assets/img/my.png'),
          test: '我的',
          link: '/my'
        }
      ]
    }
  },
  methods: {
    chooseNav (e) {
      this.current = e
    }
  },
  activated (e) {
    // console.log(e)
  },
  created () {
    const that = this
    const path = this.$router.history.current.path
    that.tabs.forEach((item, index) => {
      // eslint-disable-next-line eqeqeq
      if (path == item.link) {
        that.current = index
      }
    })
    // console.log(localStorage.getItem('indexCurrent'))
    // this.current = localStorage.getItem('indexCurrent') ? localStorage.getItem('indexCurrent') : '0'
  }
}
</script>
<style scoped lang="scss">
    .container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .list {
        position: fixed;
        background: #fff;
        bottom: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        padding: 0.05rem 0;
        border-top: 0.01rem solid rgb(243, 241, 241);
    }
    .link {
        width: 33.33%;
        color: #969799;
        flex-direction: column; /*列显示*/
    }
    span {
        font-size: 0.26rem;
        text-decoration: none !important;
    }
    img{
        width:0.5rem;
        height:0.5rem;
    }
    .active{
      color: #257CFF;
    }
</style>
