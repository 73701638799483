<template>
  <div>
      <div class="video">
		  <video-player
			ref="videoPlayer"
			class="video-player vjs-custom-skin"
			:playsinline="true"
			:options="playerOptions"
			/>
      </div>
  </div>
</template>
<script>
export default {
  name: 'videoplayer',
  data () {
    return {
        playerOptions : {
			// playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
			autoplay: false, //如果true,浏览器准备好时开始回放。
			muted: false, // 默认情况下将会消除任何音频。
			loop: false, // 导致视频一结束就重新开始。
			preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
			language: 'zh-CN',
			aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
			fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
			sources: [{
				src: 'https://securitysavetest.oss-cn-beijing.aliyuncs.com/uploads/20220426/9a43db575151f1a78f933ea3e2396007.mp4',  // 路径
				type: 'video/mp4'  // 类型
			}],
			// sources: [{
			// 	type: 'application/x-mpegURL', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
			// 	src:
			// 	'https://cdn.letv-cdn.com/2018/12/05/JOCeEEUuoteFrjCg/playlist.m3u8' // url地址，从别的博主那看来的地址，亲测可用
			// }],
			// hls: true,
			poster: "你的视频封面地址", //你的封面地址
			// width: document.documentElement.clientWidth,
			notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
			controlBar: {
				timeDivider: true,
				durationDisplay: true,
				remainingTimeDisplay: false,
				fullscreenToggle: true  //全屏按钮
			}
		}
    }
  },
  methods: {
    // 禁止拖拽
    pointerVideo () {
      let control = document.querySelectorAll('.vjs-progress-control')
      console.log(control,1566);
      control.forEach(item => {
        item.classList.add('pointer')
      })
    },
  },
  mounted () {
	  let that = this
	  that.pointerVideo()
  }

}
</script>
<style>
  /* .vjs-progress-control{
      pointer-events: none !important;
  } */
  .pointer{
    pointer-events: none !important;
  }
</style>
