let HOST = process.env.NODE_ENV|| 'test';
let baseURL = "";
let playerurl=''
console.log(HOST,'机动费奇偶狄仁杰')
if(HOST === 'development'){  //正式服正式接口
    baseURL = "https://admin.anqixing.com/";
    playerurl='https://liveh5.anqixing.com/';
}else if(HOST === 'production'){ // 正式服测试接口
    baseURL = "https://test.admin.anqixing.com/";
    playerurl='https://test.liveh5.anqixing.com/';
}else if(HOST === 'test'){ // 测试服
    baseURL = "https://security.brofirst.cn/";
    playerurl='https://securityh5live.brofirst.cn/';
}
export default {baseURL,playerurl}