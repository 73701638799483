<template>
    <div class="model_content" v-if="ifShow" @click.stop="showClick">
        <div class="model" v-if="showModal === true || showModal === 'true'">
            <img class="challenge" :src="icon" alt="" v-if="ifchallenge">
            <div class="model_title">{{title}}</div>
            <div class="model_cont" style="height: 5rem;" v-if="scroll">
              <Scroll style="height: 100%;overflow: hidden;">
                <div style="text-align: left;">{{content}}</div>
              </Scroll>
            </div>
            <div v-else class="model_cont">{{content}}</div>
            <van-count-down :time="timecont" v-if="timeshow" class="model_time"/>
            <div class="model_button display-flex-around">
                <div class="model_buttonbtn" @click="cancelFun" v-if="cancelText">{{cancelText}}</div>
                <div style="background:rgba(37.19,124.31,255,1);color:#fff" v-if="ifphoto" >
                  <div v-if="isweChat=='微信'" @click="wxChooseImage">人脸识别</div>
                  <van-uploader v-else :after-read="afterRead" type="primary" capture="camera" >人脸识别</van-uploader>
                </div>
                <div v-else >
                  <div @click="confirmFun" v-if="confirmText" style="background:rgba(37.19,124.31,255,1);color:#fff">{{confirmText}}</div>
                </div>
            </div>
            <img v-if="showClose" @click="ifShow=false" src="../assets/img/close.png" alt="" class="close">
        </div>
        <div class="model2" v-if="showModal === false || showModal === 'false'">
            <img :src="success" v-if="type=='success'" alt="" class="toastimg"/>
            <img :src="fail" v-if="type=='fail'" alt="" class="toastimg"/>
            <div class="model_title">{{title}}</div>
        </div>
        <div class="loadingbg" v-if="loading">
          <div class="loading">
          <van-loading color="#fff" size="70px" vertical="true" >验证中...</van-loading></div>
        </div>
    </div>
</template>
<script>
import Scroll from '@/components/scroll.vue'
var wx = require('jweixin-module')

const isIOS = function () {
  var isIphone = navigator.userAgent.includes('iPhone')
  var isIpad = navigator.userAgent.includes('iPad')
  return isIphone || isIpad
}
if (!window.entryCheckUrl) {
  window.entryCheckUrl = location.href.split('#')[0]
}
// 进行签名的时候
const url = isIOS() ? window.entryCheckUrl : location.href.split('#')[0]
export default {
  components: {
    Scroll
  },
  props: {
    scroll: {
      type: Boolean,
      default: false
    },
    icon: { // 挑战图标地址
      type: String
    },
    ifchallenge: { // 是否挑战
      type: [Boolean, String],
      default: false
    },
    showClose: { // 是否显示关闭按钮
      type: Boolean,
      default: false
    },
    title: { // 标题
      type: String,
      default: ''
    },
    timecont: { // 倒计时
      type: Number,
      default: 28 * 60 * 1000
    },
    cancelText: { // 取消文字
      type: String,
      default: ''
    },
    confirmText: { // 确认文字
      type: String,
      default: ''
    },
    ifShow: { // 是否展示弹框
      type: Boolean,
      default: false
    },
    content: { // 正文
      type: String,
      default: ''
    },
    showModal: { // 是否为提示框
      type: [String, Boolean],
      default: true
    },
    type: { // 提示框的类型
      type: String,
      default: 'success'
    },
    time: { // 提示框时间
      type: String,
      default: '2000'
    },
    timeshow: {
      type: Boolean,
      default: false
    },
    ifphoto: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    }
  },
  data () {
    return {
      success: require('@/assets/img/success.png'),
      fail: require('@/assets/img/fail.png'),
      loading: false,
      isweChat: '',
      jssdk: {}
    }
  },
  created () {
    if (this.name == 'face') {
      this.getSdk()
    }
    this.getEnvironment()
  },
  watch: {
    ifShow () {
      if (this.showModal === 'false') {
        setTimeout(() => {
          this.ifShow = false
        }, this.time)
      }
    }
  },
  methods: {
    cancelFun () {
      this.$emit('cancelFun')
    },
    confirmFun () {
      if (this.ifphoto) {

      } else {
        this.$emit('confirmFun')
      }
    },
    showClick () {
      this.$emit('showClick')
    },
    // 获取环境
    getEnvironment () {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.includes('micromessenger')) {
        this.isweChat = '微信'
      } else {
        this.isweChat = '浏览器'
      }
    },
    // 获取wx-js-sdk
    async getSdk (f) {
      const that = this
      const result = await that.api.getSdk({
        // url: encodeURIComponent(location.origin + location.pathname) + location.search
        // url: encodeURIComponent(window.location.href)
        url
        // .includes('test.mobile.anqixing.com') ? 'https://test.mobile.anqixing.com' : 'https://mobile.anqixing.com'
      })
      if (result.code === 1) {
        that.jssdk = result.data.jssdk
        console.log('获取到sdk了，应该验证')
        that.config()
      }
    },
    // 注册wx-sdk
    config () {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: this.jssdk.appId, // 必填，公众号的唯一标识
        timestamp: this.jssdk.timestamp, // 必填，生成签名的时间戳
        nonceStr: this.jssdk.nonceStr, // 必填，生成签名的随机串
        signature: this.jssdk.signature, // 必填，签名
        jsApiList: this.jssdk.jsApiList // 必填，需要使用的JS接口列表
      })
      console.log('config数据', this.jssdk)
      // if (localStorage.getItem('checkingPhoto') == 1) {
      //   this.wxChooseImage()
      //   localStorage.setItem('checkingPhoto', 0)
      // }
    },

    wxChooseImage () {
      console.log('应该拍照')
      const that = this
      wx.ready(function () {
        wx.chooseImage({
          count: 1, // 默认9
          sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
          success: function (res) {
            console.log(res, '上传后的id')
            wx.getLocalImgData({
              localId: res.localIds[0], // 图片的localID
              success: async function (res) {
                const localData = res.localData // localData是图片的base64数据，可以用 img 标签显示
                console.log(res, '图片对象', location.href)
                that.loading = true
                const re = await that.api.yanpeoplepic({ url: localData })
                that.$emit('yanpeoplepic', re)
                that.loading = false
              },
              fail: err => {
                console.log(err, 'getLocalImgData失败')
              }
            })
          },
          fail: function (err) {
            console.log(err, 123456)
            // localStorage.setItem('checkingPhoto', 1)
            // location.replace(location.href)
          }
        })
      })
      // }
    },
    async afterRead (file) {
      this.loading = true
      const fromdata = new FormData()
      fromdata.append('file', file.file)
      fromdata.append('category', 'face')
      const params = { file: fromdata }
      const res = await this.api.unloadfacefile(fromdata)
      if (res.code == 1) {
        const paramss = { url: res.data.fullurl }
        const re = await this.api.yanpeoplepic(paramss)
        this.$emit('yanpeoplepic', re)
        this.loading = false
      }
    }
  }
}
</script>
<style>
    .model_content{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }
    .model{
        width: 5.9rem;
        height: auto;
        border-radius: 0.16rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 0.48rem 0;
        box-sizing: border-box;
    }
    .model2{
        width:auto;
        height: auto;
        border-radius: 0.16rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 0.48rem;
        box-sizing: border-box;
        text-align: center;
    }
    .toastimg{
        width: 0.8rem;
        height: 0.8rem;
    }
    .model_title{
      width: 70%;
      margin: 0 auto;
        text-align: center;
        font-size: 0.36rem;
        font-weight: 600;
        padding: 0.24rem 0;
        box-sizing: border-box;
    }
    .model_time{
        color: rgba(36.72,40.06,61.2,1);
        font-size: 0.58rem !important;
        margin: 0.2rem 0;
        font-weight: 700;
        text-align: center;
    }
    .model_cont{
        width: 4rem;

        /* border: 1px solid red; */
        margin: 0 auto;
        color: rgba(150,151,153,1);
        font-size: 0.24rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 0.4rem;
    }
    .model_button{
        padding-top: 0.24rem;
    }
    .model_button div{
        width: 2.36rem;
        height: 0.8rem;
        border-radius: 0.08rem;
        opacity: 1;
        font-size: 0.3rem;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 0.8rem;
    }
    .model_buttonbtn{
      background: rgba(242.25,242,245,1);
      color: rgba(100,101,102,1);
    }
    .close{
        position: absolute;
        bottom: -1.2rem;
        left: 50%;
        transform: translate(-50%,0);
        width:0.8rem;
        height: 0.8rem;
    }
    .challenge{
        width: 5.9rem;
        height:1.7rem;
        margin-top: -3.2rem;
    }
    .loadingbg{
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.7);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
    }
    .loading{
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
</style>
