import VueRouter from 'vue-router'
import Index from '../views/tabs/index'
import Home from '../views/tabs/home'
import Test from '../views/tabs/test'
import My from '../views/tabs/my'
import Begin from '../views/token/begin'
import CardToken from '../views/token/cardToken'
import Phone from '../views/token/phone'
import Forget from '../views/token/forget'
import Result from '../views/search/result'
import OpenPdf from '../views/home/openPdf'
import Location from '../views/home/location'
import Data from '../views/home/data'
import DataDetail from '../views/home/dataDetail'
import TextClass from '../views/home/textClass'
import TextClassDetail from '../views/home/textClassDetail'
import OnClass from '../views/home/onClass'
import OnClassDetail from '../views/home/onClassDetail'
import LineClass from '../views/home/lineClass'
import LineClassDetail from '../views/home/lineClassDetail'
import Card from '../views/home/card'
import Video from '../views/home/video'
import Videoplayer from '../views/home/videoplayer'
import Videotest from '../views/home/videotest'
import Linevideo from '../views/home/linevideo'
import Userinfo from '../views/my/userinfo'
import Classtest from '../views/my/classtest'
import Record from '../views/my/record'
import Recorddetail from '../views/my/recorddetail'
import Wrong from '../views/my/wrong'
import Wrongdetail from '../views/my/wrongdetail'
import Certificate from '../views/my/certificate'
import Uploadcertificate from '../views/my/uploadcertificate'
import CertificateDetail from '../views/my/certificateDetail'
import Feedback from '../views/my/feedback'
import Studyreport from '../views/my/studyreport'
import StudyreportDetail from '../views/my/studyreportDetail'
import Offlinetrain from '../views/my/offlinetrain'
import Faved from '../views/my/faved'
import Chatlist from '../views/my/chatlist'
import Chatrecord from '../views/my/chatrecord'
import Suggest from '../views/my/suggest'
import Sever from '../views/my/sever'
import Editpass from '../views/my/editpass'
import Editphone from '../views/my/editphone'
import Notice from '../views/my/notice'
import NoticeDetail from '../views/my/noticeDetail'
import Success from '../views/my/success'
import Sign from '../views/my/sign'
import Signceshi from '../views/my/signceshi'
import Day from '../views/test/day'
import Rank from '../views/test/rank'
import Examcard from '../views/test/examcard'
import Cardmy from '../views/my/cardmy'
import Examtype from '../views/test/examtype'
import Daylist from '../views/test/daylist'
import ExamDetail from '../views/test/examDetail'
import Mockexamination from '../views/test/mockexamination'
import testVideo from '../views/test/testVideo.vue'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 500)
      })
    }
  },
  routes: [{
    path: 'tabs',
    component: Index,
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: { login_require: false, title: '首页' }
      },
      {
        path: '/test',
        name: 'test',
        component: Test,
        meta: { login_require: false, title: '全能题库' }
      },
      {
        path: '/my',
        name: 'my',
        component: My,
        meta: { login_require: false, title: '我的' }
        // beforeEnter:(to,from,next)=>{
        //   if(!localStorage.getItem('token')){
        //     next({
        //       path:'/cardToken'
        //     })
        //   }else{
        //     next()
        //   }
        // }
      }
    ]
  },
  {
    path: '/begin',
    name: 'begin',
    component: Begin,
    meta: { title: '智能安全培训平台' }
  },
  {
    path: '/location',
    name: 'location',
    component: Location,
    meta: { title: '定位' }
  },
  {
    path: '/cardToken',
    name: 'cardToken',
    component: CardToken,
    meta: { title: '账号登录' }
  },
  {
    path: '/phone',
    name: 'phone',
    component: Phone,
    meta: { title: '手机号登录' }
  },
  {
    path: '/forget',
    name: 'forget',
    component: Forget,
    meta: { login_require: false, title: '忘记密码' }
  },
  {
    path: '/result',
    name: 'result',
    component: Result,
    meta: { login_require: false, title: '搜索' }
  },

  {
    path: '/openPdf',
    name: 'openPdf',
    component: OpenPdf,
    meta: { login_require: false, title: '' }
  },
  {
    path: '/data',
    name: 'data',
    component: Data,
    meta: { login_require: false, title: '资料库' }
  },
  {
    path: '/dataDetail',
    name: 'dataDetail',
    component: DataDetail,
    meta: { login_require: false, title: '资料库' }
  },
  {
    path: '/textClass',
    name: 'textClass',
    component: TextClass,
    meta: { login_require: false, title: '文本课程' }
  },
  {
    path: '/textClassDetail',
    name: 'textClassDetail',
    component: TextClassDetail,
    meta: { login_require: false, title: '课程详情' }
  },
  {
    path: '/onClass',
    name: 'onClass',
    component: OnClass,
    meta: { login_require: false, title: '在线课程' }
  },
  {
    path: '/onClassDetail',
    name: 'onClassDetail',
    component: OnClassDetail,
    meta: { login_require: false, title: '课程详情' }
  },
  {
    path: '/lineClass',
    name: 'lineClass',
    component: LineClass,
    meta: { login_require: false, title: '直播课程' }
  },
  {
    path: '/lineClassDetail',
    name: 'lineClassDetail',
    component: LineClassDetail,
    meta: { login_require: false, title: '课程直播' }
  },
  {
    path: '/card',
    name: 'card',
    component: Card
  },
  {
    path: '/video',
    name: 'video',
    component: Video,
    meta: { login_require: false, title: '视频' }
  },
  {
    path: '/videoplayer',
    name: 'videoplayer',
    component: Videoplayer,
    meta: { login_require: false, title: '视频测试' }
  },
  {
    path: '/videotest',
    name: 'videotest',
    component: Videotest,
    meta: { login_require: false, title: '视频流程测试' }
  },
  {
    path: '/linevideo',
    name: 'linevideo',
    component: Linevideo,
    meta: { login_require: false, title: '直播' }
  },
  {
    path: '/classtest',
    name: 'classtest',
    component: Classtest,
    meta: {
      login_require: false,
      title: '班级任务'
    }
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback,
    meta: {
      login_require: false,
      title: '学情反馈'
    }
  },
  {
    path: '/studyreport',
    name: 'studyreport',
    component: Studyreport,
    meta: {
      login_require: false,
      title: '学习报告'
    }
  },
  {
    path: '/offlinetrain',
    name: 'offlinetrain',
    component: Offlinetrain,
    meta: {
      login_require: false,
      title: '线下培训'
    }
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: Certificate,
    meta: {
      login_require: false,
      title: '我的证书'
    }
  },
  {
    path: '/uploadcertificate',
    name: 'uploadcertificate',
    component: Uploadcertificate,
    meta: { login_require: false, title: '证书上传' }
  },
  {
    path: '/certificateDetail',
    name: 'certificateDetail',
    component: CertificateDetail,
    meta: { login_require: false, title: '我的证书' }
  },
  {
    path: '/recorddetail',
    name: 'recorddetail',
    component: Recorddetail,
    meta: {
      login_require: false,
      title: '答题记录'
    }
  },
  {
    path: '/record',
    name: 'record',
    component: Record,
    meta: {
      login_require: false,
      title: '答题记录'
    }
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: Userinfo,
    meta: {
      login_require: false,
      title: '个人信息'
    }
  },
  {
    path: '/wrong',
    name: 'wrong',
    component: Wrong,
    meta: {
      login_require: false,
      title: '我的错题'
    }
  },
  {
    path: '/wrongdetail',
    name: 'wrongdetail',
    component: Wrongdetail,
    meta: {
      login_require: false,
      title: '我的错题'
    }
  },
  {
    path: '/studyreportDetail',
    name: 'studyreportDetail',
    component: StudyreportDetail,
    meta: { login_require: false, title: '学习报告' }
  },

  {
    path: '/faved',
    name: 'faved',
    component: Faved,
    meta: {
      login_require: false,
      title: '我的收藏'
    }
  },
  {
    path: '/chatlist',
    name: 'chatlist',
    component: Chatlist,
    meta: {
      login_require: false,
      title: '聊天记录'
    }
  },
  {
    path: '/chatrecord',
    name: 'chatrecord',
    component: Chatrecord,
    meta: {
      login_require: false,
      title: '聊天记录'
    }
  },
  {
    path: '/suggest',
    name: 'suggest',
    component: Suggest,
    meta: {
      login_require: false,
      title: '投诉与建议'
    }
  },
  {
    path: '/sever',
    name: 'sever',
    component: Sever,
    meta: { login_require: false, title: '客服' }
  },
  {
    path: '/editpass',
    name: 'editpass',
    component: Editpass,
    meta: { login_require: false, title: '修改密码' }
  },
  {
    path: '/editphone',
    name: 'editphone',
    component: Editphone,
    meta: { login_require: false, title: '修改手机号' }
  },
  {
    path: '/notice',
    name: 'notice',
    component: Notice,
    meta: {
      login_require: false,
      title: '消息通知'
    }
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    component: NoticeDetail,
    meta: { login_require: false, title: '通知' }
  },
  {
    path: '/success',
    name: 'success',
    component: Success,
    meta: { login_require: false, title: '投诉与意见' }
  },
  {
    path: '/sign',
    name: 'sign',
    component: Sign,
    meta: { login_require: false, title: '签到' }
  },
  {
    path: '/signceshi',
    name: 'signceshi',
    component: Signceshi,
    meta: { login_require: false, title: '签到' }
  },
  {
    path: '/day',
    name: 'day',
    component: Day
  },
  {
    path: '/rank',
    name: 'rank',
    component: Rank,
    meta: { login_require: false, title: '全能排行榜' }
  },
  {
    path: '/mockexamination',
    name: 'mockexamination',
    component: Mockexamination,
    meta: { login_require: false, title: '模拟考试' }
  },
  {
    path: '/examtype',
    name: 'examtype',
    component: Examtype,
    meta: { login_require: false, title: '模拟考试' }
  },
  {
    path: '/examcard',
    name: 'examcard',
    component: Examcard,
    meta: { login_require: false, title: '答题卡' }
  },
  {
    path: '/daylist',
    name: 'daylist',
    component: Daylist,
    meta: { login_require: false, title: '每日一练' }
  },

  {
    path: '/cardmy',
    name: 'cardmy',
    component: Cardmy,
    meta: { login_require: false, title: '答题卡' }
  },
  {
    path: '/examDetail',
    name: 'examDetail',
    component: ExamDetail,
    meta: { login_require: false, title: '答题' }
  },
  {
    path: '/test',
    name: 'test',
    component: testVideo
  },

  // 重定向
  {
    path: '/',
    redirect: '/home'
  }

  ]

})
// 判断是否需要登录的路由
router.beforeEach((to, from, next) => {
  if (to.matched.some(function (item) {
    return item.meta.login_require === false
  })) {
    if (!localStorage.getItem('token')) {
      router.push('/cardToken')
      next()
    } else {
      next()
    }
  } else {
    next()
  }
})
export default () => {
  return router
}
