<template>
    <div>
        <nav-bar class="navbar">
            <div slot="left" @click="goBack" class="back">
                <img src="../../assets/img/back.png" alt="" />
            </div>
            <div slot="right" class="back" style="width: 0.6rem;" @click="faved">
              <img v-if="favorite==1" src="../../assets/img/faved1.png" alt="" />
              <img v-else src="../../assets/img/iconly-线性轮廓化Light-outline-Star@2x.png" alt="" />
          </div>
        </nav-bar>
        <div class="classDetail">
            <div class="classDetail_title">{{ name }}</div>
            <div class="classDetail_content" v-html="string"></div>
            <div class="fileone" v-for="(item,index) in files" :key="index">
              <div class="display-flex">
                  <img v-if="item.type=='xlsx'||item.type=='xls'" src="../../assets/img/xlsx.png" alt="">
                  <img v-if="item.type=='pdf'" src="../../assets/img/Frame@2x (13).png" alt="">
                  <img v-if="item.type=='docx'||item.type=='doc'" src="../../assets/img/word.png" alt="">
                  <img v-if="item.type=='ppt'||item.type=='pptx'" src="../../assets/img/ppt.png" alt="">
                  <div v-if="item.name">{{item.name}}</div>
              </div>
              <div class="looker" @click="tourl(item.type,item.url)">预览</div>
            </div>
          </div>
          <model
      :ifShow="showmodel"
      confirmText="确定"
      cancelText="取消"
      title="当前未在开班时间内，学习将不会记录课时 ，是否继续学习？ "
      @confirmFun="showmodel=false"
      @cancelFun="goBack"
      ></model>
      <model
      :scroll="false"
      :ifphoto="true"
      :ifShow="showyan"
      confirmText="确定"
      cancelText="取消"
      title="身份信息验证"
      content="设置人脸识别照片为本人学习"
      @yanpeoplepic="sureyan"
      @cancelFun="cancelyan"
      ></model>
      <model
      :ifShow="showhavepic"
      confirmText="确定"
      cancelText="取消"
      title="您当前还未设置人脸识别的照片，请前去设置"
      @confirmFun="confirmFungo"
      @cancelFun="goBack"
      ></model>
    </div>
</template>
<script>
import model from '@/components/model'

import config from '@/assets/js/config.js'
export default {
  components: {
    model
  },
  data () {
    return {
      showhavepic: false,
      showyan: false,
      url: config.api,
      ifdo: false,
      string: '',
      name: '',
      images: '',
      id: '',
      favorite: '',
      time: '',
      type: '',
      href: '',
      files: [],
      showmodel: false,
      ifclass: ''

    }
  },
  watch: {
    ifdo () {
      if (this.ifdo) {
        if (this.ifclass) {
          this.goQuestion()
        } else {
          this.goQuestion2()
        }
      }
    }
  },
  created () {
    this.id = this.$route.query.id
    this.train_class_id = this.$route.query.train_class_id
    this.ifclass = this.$route.query.ifclass
    this.details()
    // this.getinfo()
  },

  methods: {
    async getinfo () {
      const result = await this.api.userinfo()
      if (result.code === 1) {
        const info = result.data.info
        if (Number(info.company_check_face) === 0) {
          this.showyan = false
          this.showhavepic = false
        } else if (Number(info.company_check_face) === 1 && Number(info.is_check_face) === 0) {
          this.showhavepic = true
          this.showyan = false
        } else if (Number(info.company_check_face) === 1 && Number(info.is_check_face) === 1) {
          this.showhavepic = false
          this.showyan = true
        }
      }
    },
    confirmFungo () {
      this.$router.push('userinfo')
    },
    tourl (type, url) {
      if (type === 'pdf') {
        this.$router.push({
          path: '/openPdf',
          query: {
            src: url
          }
        })
      } else {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url))
      }
    },
    async faved () {
      const params = {
        type: 2,
        favorite_id: this.id
      }
      const res = await this.api.deletelist(params)
      if (res.code === 1) {
        this.$toast(res.msg)
        this.details()
      }
    },
    goBack () {
      this.$router.go(-1)
    },

    // 文本课程详情
    async details () {
      const params = {
        id: this.id,
        train_class_id: this.train_class_id
      }
      const reslist = await this.api.details(params)
      if (reslist.code === 1) {
        this.name = reslist.data.detail.name
        this.string = reslist.data.detail.content
        this.images = reslist.data.detail.image
        this.favorite = reslist.data.detail.is_favorite
        this.time = reslist.data.detail.stay_time * 1000
        this.timego()
        this.files = reslist.data.detail.new_file
        const resfile = reslist.data.detail.new_file
        resfile.forEach(item => {
          item.type = ''
          const index = item.url.lastIndexOf('.')
          const ext = item.url.substr(index + 1)
          item.type = ext
        })
        this.files = resfile
        if (Number(reslist.data.detail.is_settle) === 0) {
          console.log('人脸')
          this.showmodel = true
        }
      } else {
        this.$toast(reslist.msg)
      }
    },
    sureyan (res) {
      console.log(res)
      if (res.code == 1) {
        this.$toast('验证成功')
        this.showyan = false
      } else {
        this.$toast('验证失败')
        this.goBack()
      }
    },
    cancelyan () {
      this.goBack()
    },
    goQuestion () {
      /* eslint-disable-line no-unused-vars */
      var params = {
        type: 4,
        view_len: this.time,
        lesson_id: this.id,
        train_class_id: this.train_class_id
      }
      const res = this.api.recordvideo(params)
    },
    goQuestion2 () {
      /* eslint-disable-line no-unused-vars */
      var params = {
        type: 4,
        lesson_id: this.id,
        view_len: this.time,
        train_class_id: this.train_class_id
      }
      const res = this.api.recordvideo2(params)
    },
    timego () {
      console.log(this.time)
      setTimeout(() => {
        this.ifdo = true
      }, this.time)
    }
  },
  mounted () {

  }
}
</script>
<style lang="scss" scoped>
  .navbar{
    position: fixed;
    top: 0;z-index: 9;
    background: #fff;
  }
    .back{
        font-size: 0.48rem;
    }
    .back img{
        width: 0.48rem !important;
        height: 0.48rem;
    }
    .classDetail{
        width: 100%;
        height: auto;
        padding: 0 0.32rem;
        box-sizing: border-box;
        margin-top: 0.88rem;
    }
    .classDetail_title{
        color: rgba(50,50,51,1);
        font-size: 0.34rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.52rem;
        letter-spacing: 0.01rem;
        margin-bottom: 0.32rem;
    }
    .classDetail_content{
        color: rgba(50,50,51,1);
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.48rem;
    }
    .classDetail_button{
        position: fixed;
        bottom: 0.32rem;
        left: 50%;
        transform: translate(-50%,0);
    }
    .classDetail_button{
        width: 6.86rem;
        height: 0.88rem;
        opacity: 1;
        background: rgba(37.19,124.31,255,1);
        line-height: 0.88rem;
        color: #fff;
        font-size:0.32rem;
        text-align: center;
    }
    .opacity{
        opacity: 0.5;
    }
    ::v-deep img {
      width: 100% !important;
    }
    .fileone{
        width: 100%;
        margin: 0.32rem auto 0.32rem;
        padding: 0.32rem;
        box-sizing: border-box;
        height: 1.8rem;
        border-radius: 0.08rem;
        background: rgba(255,255,255,1);
        border: 0.01rem solid #eee;
        color: rgba(50,50,51,1);
        font-size: 0.28rem;
        font-weight: 400;
    }
    .fileone img{
        width: 0.48rem !important;
        height: 0.48rem;
        margin-right: 0.16rem;
    }
    .looker{
        width: 1.18rem;
        height: 0.56rem;
        border-radius: 0.08rem;
        background: rgb(205, 223, 248);
        color:#257CFF;
        text-align: center;
        line-height: 0.56rem;
        font-size: 0.24rem;
        font-weight: 500;
        float: right;
    }
</style>
