<template>
  <div>
      <div class="video" @click="ifshow=true">
          <!-- 题目 -->
          <div style="position: relative;">
            <video-player class="video-player vjs-custom-skin"
            id="videodetail"
            @ready="setPlayTime"
            @timeupdate="timeupdate"
            @play="toplay"
            controlslist="nodownload"
            @pause="topause"
            @ended="ended"
            ref="video"
              :playsinline="true"
              :options="playerOptions"
              @my-player-state-changed-event-custom-name="playerStateChanged"
              ></video-player>
            <div class="video_shade" v-if="video_pause === true">
              <img src="../../assets/img/back3.png" alt="" class="videoback" @click="goBack">
              <img src="../../assets/img/play.png" alt="" @click="play()">
            </div>
            <div class="video_shade2" v-else>
              <img src="../../assets/img/back3.png" alt="" class="videoback" @click="goBack">
            </div>
          </div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'video',
  data () {
    return {
      loading: false,
      title: '',
      iffull: true,
      ifshow: true, // 显示
      detail: '',
      id: '',
      train_id: '',
      train_class_id: '',
      ifclass: '',
      userclassstatus: false,
      video_pause: true, // 视频播放/暂停状态
      playerOptions : {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          src: '',  // 路径
          type: 'video/mp4'  // 类型
        }, {
          src: '',
          type: 'video/webm'
        }],
        hls: false,
        poster: "你的视频封面地址", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        play: 'my-player-state-changed-event-custom-name',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true  //全屏按钮
        }
      }
    }
  },
  watch: {
    ifshow: function () {
      const that = this
      if (that.ifshow) {
        setTimeout(function () {
          that.ifshow = false
        }, 5000)
      }
    }
  },
  methods: {
    playerStateChanged(playerCurrentState) {
      console.log(playerCurrentState);
    },
    toplay(){
      // if(this.$refs.video.paused === true) {
      //   this.$refs.video.play()
      // }
      console.log(232323);
      this.video_pause = false;
    },
    topause(){
      // if(this.$refs.video.paused === false) {
      // this.$refs.video.pause()
      // }
      console.log(656565656)
      this.video_pause = true;
    },
    ended(player) {
      console.log(player.cache_,222);
      if (Number(this.detail.status) !== 1) { 
        console.log('第二次播放')
        this.ecit()
        this.finishBtn()
      }
      player.pause()
    },
    // 播放
    play () {
      const video = this.$refs.video.player
      if (this.video_pause === true) {
        this.video_pause = false;
        video.play()
      } else {
        this.video_pause = true;
        video.pause()
      }
    },
    // 全屏
    full () {
      if (this.isFullScreen()) {
        this.exitFullscreen()
      } else {
        this.requestFullscreen(document.querySelector('#videodetail'))
      }
    },
    isFullScreen () {
      return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen
    },
    // 全屏兼容代码
    requestFullscreen (ele) {
      if (ele.requestFullscreen) {
        ele.requestFullscreen()
      } else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen()
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen()
      } else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen()
      }
      this.iffull = true
    },
    // 取消全屏
    exitFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      }
      this.iffull = false
    },
    goBack () {
      this.$router.go(-1)
    },
    // 时间戳
    changeTime (oldtime) {
      let newtime= new Date(oldtime * 1000) // 时间戳为10位的时候，先补充三位在进行转换
      let hour=newtime.getHours()-8
      hour=(hour < 10 ? '0' +hour : hour)+ ':'
      const minute = (newtime.getMinutes() < 10 ? '0' + newtime.getMinutes() : newtime.getMinutes()) + ':'
      const second = newtime.getSeconds() < 10 ? '0' + newtime.getSeconds() : newtime.getSeconds()
      return hour+minute + second
    },
    // 获取当前播放时间duration
    timeupdate (player) {
      const that = this
      const current = player.cache_.currentTime
      console.log(player,11223);
      let beforecurrent = localStorage.getItem('videoTime');
      if (current && Number(current).toFixed(1) < Number(that.detail.video_length).toFixed(1)) {
        if(Number(current) > (Number(beforecurrent) + 5)) { // 判断观看时间记录间隔超过五秒，记录最新进度
          localStorage.setItem('videoTime', current)
          that.ecit();
        }
      }
    },
    // 设置播放时间
    setPlayTime (player) {
      // this.$refs.video.currentTime = Number(this.detail.view_len) >= Number(this.detail.video_length) ? 0 : Number(this.detail.view_len)
      let current_time = Number(this.detail.view_len) >= Number(this.detail.video_length) ? 0 : Number(this.detail.view_len)
      player.currentTime(current_time)
    },

    // 开始拖拽
    input (e) {
      this.$refs.video.player.pause();
      if (this.userclassstatus) {
        let current = this.detail.video_length
        const total = this.$refs.video.player.duration
        // const item = current / total * 100
        if (Number(this.detail.status) === 0) {
          current = this.$refs.video.player.currentTime
          // this.currentTime = this.changeTime(current)
        } else {
          this.$refs.video.currentTime = e * total * 0.01
          // this.currentTime = this.changeTime(e * current)
        }
      }
    },
    // 结束拖拽
    change(e){
      this.$refs.video.play();
    },
    async getVideodetail () {
      const that = this
      const params = {
        id: this.id,
        train_class_id: this.train_class_id
      }
      const res = await that.api.videodetail(params)
      if (res.code === 1) {
        that.detail = res.data.detail
        if(that.detail.is_zhonghuaanbang == 1) {
          that.playerOptions.hls = true;
        }
        that.setPlayTime(this.$refs.video.player)
        // that.timeupdate()
        let title = res.data.detail.name
        if (title !== undefined || title !== null) {
          if (title.length > 6) {
            title = title.substring(0, 6) + '...'
          }
        }
        if(that.detail.status == 1) {
          that.finishBtn()
        }
        console.log(that.playerOptions.sources,that.detail,4545454545);
        that.playerOptions.sources[0].src = that.detail.video_url
        that.playerOptions.sources[1].src = that.detail.video_url
        that.$refs.video.player.play()
        localStorage.setItem('videoTime',that.detail.view_len);
        that.title = title
        setTimeout(function () {
          that.ifshow = false
        }, 5000)
      }
    },
    // 任务完成
    finishBtn () {
      let control = document.querySelectorAll('.vjs-progress-control')
      control.forEach(item => {
        item.classList.add('pointer')
      })
    },
    ecit () {
      if (this.ifclass) {
        const params = {
          type: 1,
          lesson_id: this.detail.lesson_id,
          class_id: this.detail.id,
          view_len: this.$refs.video.player.cache_.currentTime ? (this.$refs.video.player.cache_.currentTime).toFixed(1) : 0,
          train_class_id: this.train_class_id
        }
        const res = this.api.recordvideo(params)
        if (res.code == 1 && Number(this.detail.status) === 0) {
          this.getVideodetail()
        }
      } else {
        const params = {
          type: 1,
          lesson_id: this.detail.lesson_id,
          class_id: this.detail.id,
          view_len: (this.$refs.video.player.cache_.currentTime).toFixed(1)
        }
        const res = this.api.recordvideo2(params)
        if (res.code == 1 && Number(this.detail.status) === 0) {
          this.getVideodetail()
        }
      }
    },
    unloadHandler(e) {
      this.ecit();
    }
  },
  // beforeDestroy () {
  //   this.ecit()
  // },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.unloadHandler(e))
    window.removeEventListener('popstate', e => this.unloadHandler(e))
    window.removeEventListener('pagehide', e => this.unloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  mounted () {
    let mobile_type = this.judgeBrand(navigator.userAgent.toLowerCase())
    if(mobile_type == 'iphone') {
      this.playerOptions.controlBar.fullscreenToggle = false
    }
    if (this.$route.query.train_class_id) {
      this.userclassstatus = true
    } else {
      this.userclassstatus = false
    }
    this.id = this.$route.query.id
    this.train_id = this.$route.query.train_id
    this.ifclass = this.$route.query.ifclass
    this.train_class_id = this.$route.query.train_class_id
    this.getVideodetail()
    this.loading = true
    document.getElementById('videodetail').addEventListener('ended', () => { //该视频播放是否已结束
      this.ecit();
    }, false)
    window.addEventListener('beforeunload', e => this.unloadHandler(e))
    window.addEventListener('popstate', e => this.unloadHandler(e))
    window.addEventListener('pagehide', e => this.unloadHandler(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
  }

}
</script>
<style  >
  *{
      padding: 0;
      margin: 0;
  }
  /* .vjs-progress-control{
      pointer-events: none !important;
  } */
  .video{
      width: 100%;
      height: 4.2rem;
      position: relative;
  }
  .video video{
      width: 100%;
      height: 4.2rem;
      position: absolute;
      top: 0;
      left: 0;
  }
  .controls{
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg,rgba(0,0,0,0), rgba(0,0,0,0.7) );
      width: 100%;
      height: 1.44rem;
      padding:0.32rem;
      box-sizing: border-box;
      font-size: 0.34rem;
      color: #fff;
      z-index: 2;
  }
  .video_title{
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180.25deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
      width: 100%;
      height: 1.44rem;
      padding:0.32rem;
      box-sizing: border-box;
      font-size: 0.34rem;
      color: #fff;
      z-index: 2;
  }
  .video_title img{
      width: 0.48rem;
      height: 0.48rem;
  }
  .video_title_detail{
      flex: 1;
      text-align: left;
  }
  .video_line{
      margin:0 0.12rem;
  }
  .status_item{
      width: auto;
      height:0.6rem;
      border-radius: 0.4rem;
      justify-content: center;
      font-size:0.24rem;
      background: rgba(0, 0, 0, 0.4);
      padding:0 0.2rem;
      box-sizing: border-box;
  }
  .status_item img{
      width: 0.24rem;
      height: 0.24rem;
      margin-right: 0.12rem;
  }
  .share{
      width: 80%;
      height: 4.2rem;
      opacity: 1;
      background: rgba(0,0,0,0.5);
      position: fixed;
      top: 0;
      right: 0;
      z-index: 5;
  }
  .share_one{
      color: rgba(255,255,255,1);
      font-size: 0.24rem;
      text-align: center;
  }
  .share_one img{
      width: 0.8rem;
      height: 0.8rem;
  }
  .close{
      width: 6.24rem;
      height: 100%;
      opacity: 1;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 4;
  }
  .share_cont{
      width: 6.24rem;
      position: fixed;
      top: 1.12rem;
      right: 0%;
      z-index: 5;
  }
  .begin,.pause{
      width: 0.32rem !important;
      height:0.32rem !important;
  }
  .progresstime{
      flex: 1;
      text-align: left;
      margin-left: 0.12rem;
      font-size: 0.28rem;
  }
  .progress{
      width: 100%;
      margin: 0.12rem auto 0;
  }
  .video_shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4.2rem;
    z-index: 999999;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video_shade2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .videoback {
    position: absolute;
    left: 0;
    top: 0;
    padding-left: .3rem;
    padding-top: .3rem;
    width: .5rem;
    height: .5rem;
  }
  .vjs-big-play-button,.video-js.vjs-ended .vjs-big-play-button {
    display: none !important;
  }
  
  .video_shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4.2rem;
    z-index: 999999;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
