<template>
    <div>
        <nav-bar>
            <div slot="left" class="back">
                <img src="../../assets/img/back.png" alt="" @click="goBack" />
            </div>
        </nav-bar>
        <div class="token_content">
            <img src="../../assets/img/logo2.png" alt="" class="content_logo"/>
            <div class="content_input display-flex-between">
                <input type="number" placeholder="手机号码" v-model="phone" />
            </div>
            <div class="content_input display-flex-between">
                <input type="number"  placeholder="短信验证码" v-model="code" />
                <div class="code" @click="getCode">{{codeValue}}</div>
            </div>
            <div class="content_input display-flex-between">
                <input :type="islook?'text':'password'" maxlength="12"  placeholder="设置6-20位登录密码" v-model="password"/>
                <div @click="islook=!islook">
                    <img :src="islook?require('../../assets/img/eye2.png'):require('../../assets/img/look.png')" alt="" class="content_input_eye"/>
                </div>
            </div>
            <div class="content_input display-flex-between">
                <input :type="islook2?'text':'password'" maxlength="12"  placeholder="请再次输入密码" v-model="password2" />
                <div @click="islook2=!islook2">
                  <img :src="islook2?require('../../assets/img/eye2.png'):require('../../assets/img/look.png')" alt="" class="content_input_eye"/>
                </div>
            </div>
            <div class="button_token" :class="isclick?'':'opacity'" @click="over">完成</div>

        </div>
    </div>
</template>
<script>
import '@/assets/style/token.css'
export default {
  data () {
    return {
      phone: '',
      code: '',
      password: '',
      password2: '',
      islook: false,
      islook2: false,
      codeValue: '获取验证码',
      number: 300,
      timer: ''
    }
  },
  computed: {
    isclick: {
      set: function () {},
      get: function () {
        return this.phone !== '' && this.code !== '' && this.password !== '' && this.password2 !== ''
      }
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    getTime () {
      this.timer = setInterval(() => {
        this.number--
        this.codeValue = this.number + 's后重新获取'
        if (this.number === 0) {
          clearInterval(this.timer)
          this.codeValue = '重新获取'
        }
      }, 1000)
    },
    getCode () {
      var reg = /^1[3|4|5|7|6|8][0-9]{9}$/
      if (this.phone === '') {
        this.$toast('请输入手机号')
      } else if (!reg.test(this.phone)) {
        this.$toast('请输入正确的手机号')
      } else {
        this.getTime()
        this.getphonecode()
      }
    },
    async getphonecode () {
      const that = this
      const params = {
        event: 'resetpwd',
        mobile: that.phone
      }
      const reslist = await that.api.phonetoken(params)
      if (reslist.code === 1) {
        this.$toast('发送成功')
      } else {
        that.$toast(reslist.msg)
      }
    },
    async over () {
      if (this.password !== this.password2) {
        this.$toast('请输入相同密码')
      } else {
        const that = this
        const params = {
          captcha: that.code,
          newpassword: that.password,
          confirmpassword: that.password2,
          mobile: that.phone
        }
        const reslist = await that.api.findpassword(params)
        if (reslist.code === 1) {
          that.$toast(reslist.msg)
          that.$router.go(-1)
        } else {
          that.$toast(reslist.msg)
        }
      }
    }
  }

}
</script>
<style lang="scss" scoped>

</style>
