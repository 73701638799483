<template>
    <div class="mybody">
        <img src="../../assets/img/bg.png" alt="" class="mybg"/>
        <nav-bar class="mynavbar">
            <div slot="right" class="new" >
              <router-link to="/notice">
              <div style="position:relative;">
                <img src="../../assets/img/new.png" aria-modal="true" alt="">
                <div class="number" v-if="number!=0">{{number}}</div>
              </div>
              </router-link>
            </div>
        </nav-bar>
        <div class="scrollmy" :style="{height:+height+'px'}">
          <Scroll
          :scrollX="scrollX"
          style="height: 100%;"
          ref="scroll"
          :probeType="3"
          :pullup="true">
          <div style="height:auto;padding-bottom: 0.5rem;box-sizing: border-box;">
              <div class="display-flex-between userinfo">
                <div class="userinfo_img">
                  <van-image
                          width="100%"
                          height="100%"
                          fit="cover"
                          :round="true"
                          :src="userinfo.avatar"
                        />
                </div>
                  <div class="userinfo_info ">
                      <div class="userinfo_info_name">{{userinfo.nickname}}</div>
                      <div class="userinfo_info_phone display-flex">
                          <img src="../../assets/img/phone.png" alt="">
                          <p>{{userinfo.mobile}}</p>
                      </div>
                      <div class="userinfo_info_phone userinfo_info_post">{{userinfo.department_name}}
                        <span v-if="userinfo.pos_name">-</span>{{userinfo.pos_name}}</div>
                  </div>
                  <router-link to="/userinfo" class="userinfo_edit">
                    <img src="../../assets/img/edit.png" alt="" >
                  </router-link>
              </div>
              <div class="categorybg">
              <div class="category display-flex-between">
                  <div v-for="(item,index) in category" class="category_item" :key="index" @click="toTabslist(index)">
                      <img :src="item.img" alt="" class="category_item_img"/>
                      <p class="category_item_title">{{item.title}}</p>
                  </div>
              </div>
              </div>
              <div class="list">
                  <div v-for="(item,index) in list" class="list_item display-flex-between" :key="index" @click="toNav(index)">
                      <img :src="item.img" alt="" v-if="item.title" class="list_item_img"/>
                      <p class="list_item_title">{{item.title}}</p>
                      <img src="../../assets/img/you2.png" v-if="item.title" alt="">
                  </div>
              </div>
          </div>
          </Scroll>
        </div>
        <div class="choose_organ" v-if="show_choose_class">
          <div class="choose_organ_same" @click="show_choose_class = false" @touchmove.prevent @mousewheel.prevent></div>
          <div class="organ_content">
            <div class="organ_title">
              选择班级
            </div>
            <div class="organ_list" v-for="(item,index) in class_list" :key="index">
              <div class="organ_info" @click="toclasstest(item.id)">{{item.name}}</div>
            </div>
            <div class="organ_close" @click="show_choose_class = false" @touchmove.prevent @mousewheel.prevent>
              <img src="../../assets/img/ic-close@2x.png" alt="" class="organ_close_img">
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import Scroll from '@/components/scroll.vue'
export default {
  components: {
    Scroll
  },
  data () {
    return {
      height: '',
      scrollX: false,
      pulldown: false,
      number: 0,
      userinfo: '',
      unread_count: 0,
      category: [
        {
          img: require('@/assets/img/m1.png'),
          title: '班级任务'
        }, {
          img: require('@/assets/img/m3.png'),
          title: '学情反馈'
        }, {
          img: require('@/assets/img/m4.png'),
          title: '学习报告'
        }
      ],
      list: [
        {
          img: require('@/assets/img/list1.png'),
          title: '我的错题'
        }, {
          img: require('@/assets/img/list2.png'),
          title: '答题记录'
        }, {
          img: require('@/assets/img/list3.png'),
          title: '我的证件'
        }, {
          img: require('@/assets/img/list4.png'),
          title: '我的收藏'
        }, {
          img: require('@/assets/img/list5.png'),
          title: '我的聊天记录'
        }, {
          img: require('@/assets/img/list6.png'),
          title: '投诉与建议'
        }, {
          img: require('@/assets/img/list7.png'),
          title: '联系客服'
        }, {
          img: require('@/assets/img/list1.png'),
          title: '退出登录'
        }, {
          img: '',
          title: ''
        }
      ],
      class_list: [],
      show_choose_class: false
    }
  },

  methods: {
    toTabslist (e) {
      if (e === 0) {
        this.myclass();
        // this.$router.push('/classtest')
      } else if (e === 1) {
        this.$router.push('/feedback')
      } else {
        this.$router.push('/studyreport')
      }
    },
    toNav (e) {
      if (e === 0) {
        this.$router.push('/wrong')
      } else if (e === 1) {
        this.$router.push('/record')
      } else if (e === 2) {
        this.$router.push('/certificate')
      } else if (e === 3) {
        this.$router.push('/faved')
      } else if (e === 4) {
        // this.$toast('该功能在开发中，敬请期待！')
        this.$router.push('/chatlist')
      } else if (e === 5) {
        this.$router.push('/suggest')
      } else if (e === 6) {
        this.$router.push('/sever')
      } else {
        const result = this.api.logout()
        result.then(res => {
          if (res.code === 1) {
            this.$router.push('/cardToken')
          } else {
            this.$toast(res.msg)
          }
        })
      }
    },
    async myclass () {
      const that = this
      const params = {}
      console.log(params)
      const resdata = await that.api.myclass(params)
      if (resdata.code === 1) {
        console.log(resdata);
        that.class_list = resdata.data.list;
        that.show_choose_class = true;
      } else {
        that.$toast(resdata.msg)
      }
    },
    toclasstest(id){
      this.$router.push({ path: "/classtest", query: { id: id } });
    },
    async newnumbwe () {
      const res = await this.api.unreadCount()
      if (res.code === 1) {
        this.number = res.data.count
      }
    },
    async getuserinfo () {
      const that = this
      const result = await that.api.userinfo()
      if (result.code === 1) {
        that.userinfo = result.data.info
      }
    }
  },
  async mounted () {
    const that = this
    that.height = document.documentElement.clientHeight - 100
    that.getuserinfo()
    that.newnumbwe()
  }
}
</script>
<style scoped lang="scss">
  .mybody{
    height:100vh;
  }
  .scrollmy{
    width: 100%;
    overflow: hidden;
    margin-top: 1rem;
    /* border: 0.01rem solid red; */
}
  .mynavbar{
    position: fixed;
    top: 0;
    z-index: 5;
  }
    .mybg{
        width: 100%;
        height: 3.4rem;
        position: fixed;
        top: 0;
        z-index: -1;
    }

    .new{
      position: relative;
    }
    .new .number{
      position: absolute;
      width: 0.3rem;
      height: 0.3rem;
      top: 0.4rem;
      right: -0.1rem;
      border-radius: 50%;
      background-color: red;
      font-size: 0.12rem;
      text-align: center;
      line-height: 0.3rem;
      color: #fff;
    }
    .new img{
        width: 0.42rem;
        height: 0.4rem;
    }
    .userinfo{
        width: 100%;
        height:2.32rem;
        padding: 0 0.32rem;
        box-sizing: border-box;
        /* margin-top: 1.9rem; */
        color: #fff;
        align-items: flex-start !important;
    }
    .userinfo_img{
        width: 1.44rem;
        height: 1.44rem;
        border-radius: 50%;
        margin-right: 0.45rem;
    }
    .userinfo_info{
        flex: 1;
    }
    .userinfo_info_name{
        font-size: 0.4rem;
        font-weight: 600;
    }
    .userinfo_info_phone{
        font-size: 0.24rem;
        font-weight: 500;
        margin: 0.15rem 0;
    }
    .userinfo_info_post{
        font-weight: 300;
    }
    .userinfo_info img{
        width: 0.21rem;
        height: 0.21rem;
        margin-right: 0.05rem;
    }
    .userinfo_edit{
        width: 0.42rem;
        height: 0.42rem;
    }
    .userinfo_edit img{
        width: 0.42rem;
        height: 0.42rem;
    }
    .categorybg{
      width: 100%;
        height: auto;
        background: #fff;
    }
    .category{
        /* padding: 0.08rem 0; */
        box-sizing: border-box;

    }
    .category_item_img{
        width: 0.64rem;
        height: 0.64rem;
    }
    .category_item_title{
        font-size: 0.24rem;
    }
    .list{
      width: 100%;
      height: auto;
      background: #fff;
        padding: 0.4rem 0.32rem;
        box-sizing: border-box;
    }
    .list_item{
        width: 100%;
        height: auto;
        padding: 0.32rem 0;
        box-sizing: border-box;
        border-bottom: 0.001rem solid #f8f8f8;
        margin: 0;
    }
    .list_item_img{
        margin-right: 0.16rem;
    }
    .list_item img{
        width: 0.32rem;
        height: 0.32rem;
    }
    .list_item_title{
        flex: 1;
    }
    .unread_num {
      position:absolute;
      right:-.05rem;
      top:.4rem;
      width:.25rem;
      height:.25rem;
      background:red;
      border-radius:50%;
      font-size: .05rem;
      color: white;
      text-align: center;
    }
    
    /*选择班级样式*/
    .choose_organ {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        left: 0;
    }

    .choose_organ_same {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .organ_content {
        width: 100%;
        position: absolute;
        background: #FFFFFF;
        height: 10rem;
        bottom: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 2;
        overflow-y: auto;
    }

    .organ_title {
        font-size: .36rem;
        text-align: center;
        line-height: 1.2rem;
    }

    .organ_list {
        margin: 0 .24rem;
        font-size: .24rem;
    }

    .organ_info {
        line-height: .8rem;
        border-bottom: .01rem solid #dedede;
    }

    .organ_close {
        position: absolute;
        right: 0.4rem;
        top: 0.4rem;
    }

    .organ_close_img {
        width: .4rem;
        height: .4rem;
        display: flex;
    }
</style>
